import { RichTextStoryblok } from 'lib/storyblok/types'

import { CMSRichText } from './CMSRichText'

type Props = {
  block: RichTextStoryblok
}

export const RichText = ({ block, ...props }: Props): JSX.Element => {
  const { richtext } = block
  return (
    <section className="overflow-hidden" {...props}>
      <div className="max-w-rich-text mx-auto py-10 px-5 md:px-0 lg:py-15">
        <CMSRichText
          richtext={richtext}
          renderer={(type, renderDefault) => {
            if (type === 'blok') {
              return renderDefault()
            } else {
              return renderDefault()
            }
          }}
        />
      </div>
    </section>
  )
}
